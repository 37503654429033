<template>
  <vx-card title="Proyectos Inmobiliarios: Papelera de reciclaje">
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-table max-items="10" pagination search stripe :data="entries" :hoverFlat="true">
          <template slot="thead">
            <vs-th sort-key="name">Nombre</vs-th>
            <vs-th sort-key="construction_company">Desarrollador</vs-th>
            <vs-th sort-key="state">Departamento</vs-th>
            <vs-th sort-key="deleted_at">Fecha de eliminación</vs-th>
<!--            <vs-th>Acciones</vs-th>-->
          </template>

          <template slot-scope="{data}">
            <tbody>
              <vs-tr :data="record" :key="index" v-for="(record, index) in data">
                <vs-td>
                  <p class="font-medium">{{ record.name }}</p>
                </vs-td>
                <vs-td>
                  <p class="font-medium">{{ record.construction_company }}</p>
                </vs-td>
                <vs-td>
                  <p class="font-medium">{{ record.state.name }}</p>
                </vs-td>
                <vs-td>
                  <p class="font-medium">{{ record.deleted_at }}</p>
                </vs-td>
<!--                <vs-td>-->
<!--                  <div class="flex">-->
<!--                    <vx-tooltip text="Restaurar">-->
<!--                      <vs-button-->
<!--                          :disabled="!($can('create', 'project') || $can('update', 'project'))"-->
<!--                          radius size="large" color="success" type="flat" icon-pack="feather" icon="icon-refresh-cw"-->
<!--                          @click="confirmRestore(record)" @click.stop-->
<!--                      />-->
<!--                    </vx-tooltip>-->
<!--                  </div>-->
<!--                </vs-td>-->
              </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </div>
    </div>
  </vx-card>
</template>

<script>
import RealStateProjectsClient from "../../utils/realStateProjectsClient";
import Notifier from "./../../utils/notification";
import * as notificationMixins from "../../utils/notificationMixins";

let notifier = new Notifier();
const ENTRIES_CLIENT = new RealStateProjectsClient();

export default {
  data() {
    return {
      entries: [],
    };
  },

  mounted() {
    this.$vs.loading();
    this.getEntries();
  },

  methods: {
    async getEntries(showLoading = true) {
      if (showLoading) this.$vs.loading();
      await ENTRIES_CLIENT.listDeletedProjects()
          .then(response => {
            this.entries = response.data;
            if (showLoading) this.$vs.loading.close();
          })
          .catch(error => {
            if (showLoading) this.$vs.loading.close();
            notifier.notification("error");
          });
    },

    confirmRestore(entry) {
      notificationMixins.RestoreEntryNotification.fire()
          .then(result => {
            if (result.value) {
              if (entry.id !== null && entry.id !== undefined && entry.id !== "") {
                this.$vs.loading();
                ENTRIES_CLIENT.restore({pathParameters: {entryId: entry.id}})
                    .then(response => {
                      notifier.notification("restored");
                      this.getEntries();
                    })
                    .catch(error => {
                      notifier.alertMessage("error");
                    });
                this.$vs.loading.close();
              }
            }
          });
    },
  },
};
</script>

<style scoped>
.vs-table--tbody-table .tr-values.selected {
  cursor: default;
}
</style>
